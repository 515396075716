<template>
  <div>
    <a-modal
      title="库存详情"
      v-model:visible="visible"
      dialogClass="stock"
      @ok="handleOk"
      width="70vw"
    >
      <template v-if="info">
        <div class="agentInfo">
          <div class="left">
            <p>agentId：{{ info.agentId }}</p>
            <p>手机号：{{ info.phone }}</p>
            <p>姓名：{{ info.name }}</p>
          </div>
          <div class="right">
            <a-button @click="onClick" type="primary">{{
              editing ? "完成" : "编辑库存"
            }}</a-button>
          </div>
        </div>
        <div class="ticketList">
          <div
            class="ticketList_item"
            v-for="(item, index) in info.productList"
            :key="index"
          >
            <a-image :src="item.mainImg" />
            <p class="ticketList_item_name">
              <a-tooltip>
                <template #title
                  >{{ item.name }}/ {{ item.agentTicketStock
                  }}{{ item.unit }}</template
                >
                <p class="ticketList_item_name_price">
                  <span>{{ item.ticketPrice }}元/</span>
                  <span>{{ item.unit }}</span>
                </p>
                <p class="ticketList_item_name_tipmain">
                  <span class="ticketList_item_name_name">{{ item.name }}</span>
                  <span>/ {{ item.agentTicketStock }}{{ item.unit }}</span>
                </p>
              </a-tooltip>
            </p>
            <p class="ticketList_item_input" v-if="editing">
              <span>增加：</span>
              <a-input-number
                size="small"
                id="inputNumber"
                v-model:value="item.addStock"
                :max="99999"
              />
              <span>{{ item.unit }}</span>
            </p>
          </div>
        </div>
      </template>
    </a-modal>
    <a-modal
      title="核对添加库存信息"
      v-model:visible="showChangeList"
      @ok="handleChangeConfirm"
      width="60vw"
    >
      <div class="addstock_changeList">
        <div class="addstock_changeList_header">
          <span>票种</span>
          <span>图片</span>
          <span>单价</span>
          <span>单位</span>
          <span>当前库存</span>
          <span>操作库存</span>
          <span>最后结果</span>
        </div>
        <div v-for="item in changeList" :key="item.id">
          <span>{{ item.name }}</span>
          <span><img :src="item.mainImg" /></span>
          <span>{{ item.ticketPrice }}</span>
          <span>{{ item.unit }}</span>
          <span>{{ item.agentTicketStock }}</span>
          <span>{{ item.addStock }}</span>
          <span>{{ item.agentTicketStock + item.addStock }}</span>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { ref, watch } from "vue";
import { getAgentStock, addAgentStock } from "@/utils/api";
import { message } from "ant-design-vue";

export default {
  props: ["show", "data"],
  setup(prop, context) {
    const visible = ref(false);
    const info = ref(null);
    const editing = ref(false);

    async function loadData() {
      const res = await getAgentStock(prop.data.agentId);
      if (res.code == 0) {
        res.data.productList.forEach((item) => {
          item.addStock = 0;
        });
        info.value = res.data;
      }
      console.log(res);
    }
    watch(visible, (val) => {
      context.emit("update:show", val);
      val ? loadData() : (editing.value = false);
    });

    watch(
      prop,
      (val) => {
        if (visible.value != val.show) {
          visible.value = val.show;
        }
      },
      { deep: true }
    );

    const changeList = ref([]);
    const showChangeList = ref(false);
    async function addStock() {
      const { productList } = info.value;
      console.log(productList);
      console.log(productList.some((item) => item.addStock > 0));
      changeList.value = productList.filter((item) => item.addStock != 0);
      if (changeList.value.length) {
        showChangeList.value = true;
        console.log(showChangeList.value);
      } else {
        editing.value = false;
      }
    }
    async function handleChangeConfirm() {
      const { productList, agentId } = info.value;
      const products = productList.map((item) => {
        const v = { productInfoId: item.id, ticketNumber: item.addStock };
        return v;
      });
      const req = { products, agentId };
      const res = await addAgentStock(req);
      console.log(res);
      if (res.code == 0) {
        message.success("操作成功");
        editing.value = false;
        showChangeList.value = false;
        loadData();
      }
    }

    function onClick() {
      if (editing.value) {
        addStock();
      } else {
        editing.value = true;
      }
    }

    function handleOk() {
      if (editing.value) {
        addStock();
      } else {
        visible.value = false;
      }
    }

    return {
      visible,
      info,
      editing,
      onClick,
      handleOk,
      showChangeList,
      changeList,
      handleChangeConfirm,
    };
  },
};
</script>

<style lang="scss">
.stock {
  .agentInfo {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .ticketList {
    display: flex;
    flex-wrap: wrap;
    .ticketList_item {
      text-align: center;
      margin-left: 12px;
      margin-top: 12px;
      img {
        width: 100px;
        height: 100px;
      }
      .ticketList_item_name {
        text-align: center;
        .ticketList_item_name_price {
          margin-bottom: 0;
          font-size: 12px;
        }
        .ticketList_item_name_tipmain {
          margin-bottom: 0px;
          display: flex;
          align-items: center;
        }
        .ticketList_item_name_name {
          white-space: nowrap;
          text-overflow: ellipsis;
          display: inline-block;
          width: 50px;
          overflow: hidden;
          font-size: 12px;
        }
      }
      .ticketList_item_input {
        font-size: 12px;
      }
    }
  }
  .ant-input-number-sm {
    width: 50px !important;
  }
}
.addstock_changeList {
  > div {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    > span {
      border: 1px solid #eee;
      padding: 0 12px;
      line-height: 50px;
    }
    img {
      width: 50px;
      height: 50px;
    }
  }
  .addstock_changeList_header {
    background-color: #ccc;
  }
}
</style>